<script lang="ts" setup>
interface InputProps {
    id?: string
    label?: string
    modelValue?: string | number | Blob
    disabled?: boolean
    required?: boolean
    horizontal?: boolean
    autocomplete?: string
    suffix?: string
    step?: number
    type?:
        | 'text'
        | 'checkbox'
        | 'textarea'
        | 'email'
        | 'password'
        | 'tel'
        | 'file'
        | 'color'
        | 'url'
        | 'search'
        | 'number'
        | 'date'
        | 'datetime-local'
        | 'hidden'
    error?: string | null
    separator?: boolean
}

const props = withDefaults(defineProps<InputProps>(), {
    disabled: false,
    required: false,
    horizontal: false,
    autocomplete: 'off',
    type: 'text',
    step: 1,
    error: null,
})

const emit = defineEmits(['update:modelValue'])
const value = computed<string | Blob | number | undefined>({
    get() {
        return props?.modelValue
    },
    set(value) {
        emit('update:modelValue', value)
    },
})

const slots = useSlots()
const hasSlot = computed(() => !!slots.default)

const suffix = computed(() => {
    return props.suffix ? ' ' + props.suffix : null
})

const fileName = ref('')
const setFileBlob = (e: Event) => {
    const fileList = (e.target as HTMLInputElement)?.files || []
    if (fileList.length > 0) {
        fileName.value = fileList[0].name
        value.value = fileList[0]
    }
}

const $style = useCssModule()
const classes = computed(() => {
    return [
        $style.root,
        props.type && $style[`root--type-${props.type}`],
        props.type === 'file' && $style['root--type-file'],
        props.separator && $style['root--has-separator'],
        props.error && $style['has-errors'],
        props.horizontal && $style.horizontal,
        props.required && $style.required,
        props.disabled && $style.disabled,
    ]
})
</script>

<template>
    <div :class="classes">
        <label v-if="label" :class="$style.label" :for="id">{{ label }}</label>
        <div :class="$style['input-group']">
            <input
                v-if="!hasSlot && type === 'file'"
                :id="id"
                type="file"
                :class="$style.input"
                :disabled="disabled"
                :required="required"
                @change="setFileBlob"
            />
            <input
                v-else-if="!hasSlot && type === 'number'"
                :id="id"
                v-model.number="value"
                :class="$style.input"
                :disabled="disabled"
                :required="required"
                :step="step"
                :autocomplete="autocomplete"
                type="number"
            />
            <textarea
                v-else-if="!hasSlot && type === 'textarea'"
                :id="id"
                v-model="value"
                :class="$style.input"
                :disabled="disabled"
                :required="required"
                :autocomplete="autocomplete"
            ></textarea>
            <input
                v-else-if="!hasSlot"
                :id="id"
                v-model="value"
                :class="$style.input"
                :disabled="disabled"
                :required="required"
                :autocomplete="autocomplete"
                :type="type"
            />
            <slot />
            <template v-if="!hasSlot && type === 'file'">
                <SvgIcon :class="$style['download-icon']" name="download" view-box="0 0 24 24" width="24" height="24" />
                <span>{{ fileName ? fileName : $t('choose_file') }}</span>
            </template>

            <template v-if="suffix">{{ suffix }}</template>
        </div>
        <p v-if="error" :class="$style['error-message']">{{ error }}</p>
    </div>
</template>

<style lang="scss" module>
@mixin input {
    width: 100%;
    height: var(--v-input-height, var(--input-height));
    padding: var(--spacing-2-xs) var(--spacing-2-xs);
    border: 1px solid var(--color-border-primary);
    border-radius: var(--radius-sm);
    background-color: var(--color-white);
    color: var(--color-primary);
    font-size: rem(16);
    transition: 0.2s ease(in-out-cubic);
    transition-property: border-color, background-color;

    &:focus {
        background-color: #f6f6f6;
    }
}

.root {
    width: var(--v-input-root-width, 100%);

    &--has-separator {
        padding-bottom: rem(12);
        border-bottom: 1px solid var(--color-line-primary);
    }

    &--type-checkbox {
        --v-input-label-line-height: 1.6;
        --v-input-height: 1.6lh;
        --v-input-horizontal-align-item: flex-start;
    }

    select {
        overflow: hidden;
        width: 100%;
        height: var(--input-height);
        padding: var(--input-padding, 0 var(--spacing-2-xs));
        border: 1px solid var(--color-border-primary);
        border-radius: var(--input-border-radius, var(--radius-sm));
        appearance: none;
        background-color: var(--color-white);
        background-image: var(
            --input-background-image,
            url("data:image/svg+xml;utf8, <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'> <path d='M8 10L12 14L16 10' stroke='currentColor' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/> </svg>")
        );
        background-position-x: calc(100% - 16px);
        background-position-y: 50%;
        background-repeat: no-repeat;
        color: var(--color-primary);
        font-size: 14px;
        opacity: 1; // select has 0.7 opacity when disabled
        text-overflow: ellipsis;
        transition: border-color 0.2s ease(in-out-cubic);
        white-space: nowrap;

        &:focus {
            border-color: var(--color-black);
            outline: none;
        }

        @media (hover: hover) {
            &:hover {
                border-color: var(--color-black);
            }
        }

        .disabled &,
        &:disabled {
            border-color: rgb(159 159 159 / 70%);
            color: var(--input-disabled-color, var(--color-primary-disabled));
        }
    }
}

.label {
    display: block;
    margin-bottom: 0.5rem;
    color: color(primary);
    font-size: 12px;
    font-weight: 500;
    line-height: var(--v-input-label-line-height, 120%);

    .root--type-checkbox & {
        cursor: pointer;
    }

    @media (prefers-contrast: no-preference) {
        .disabled &,
        &:disabled {
            color: var(--color-primary-disabled);
        }
    }
}

.input-group {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .root--type-file & {
        @include input;

        padding-right: rem(24 + 16 + 6);
    }
}

.horizontal {
    display: flex;
    width: auto;
    flex-direction: row;
    align-items: var(--v-input-horizontal-align-item, center);
    gap: var(--spacing-xs);

    .label {
        order: 2;
        margin: 0;
    }

    input {
        width: auto;
        order: 1;
        margin: 0;
    }
}

.required {
    .label::after {
        margin-left: 0.25rem;
        color: color(error);
        content: '*';
    }
}

.input {
    cursor: inherit;

    .root:not(.root--type-file) & {
        @include input;
    }

    &:hover,
    &:focus {
        border-color: var(--color-black);
        outline: none;
    }

    .disabled &,
    &:disabled {
        border-color: var(--color-primary-disabled);
        color: var(--color-primary-disabled);
    }

    &[type='checkbox'] {
        cursor: pointer;
    }

    &[type='file'] {
        position: absolute;
        width: 100%;
        cursor: pointer;
        inset: 0;
        opacity: 0;
    }

    &:is(textarea) {
        min-height: 100px;
        resize: vertical;
    }
}

.file-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.download-icon {
    position: absolute;
    right: rem(16);
    pointer-events: none;
}

.error-message {
    margin: 0.5rem 0 1rem;
    color: color(error);
    font-size: 0.8rem;
}

.has-errors {
    .label {
        color: color(error);
    }

    select,
    textarea,
    input {
        border-color: color(error);
    }
}
</style>
